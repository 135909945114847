import { debounceTime, Subject } from 'rxjs';
import { LocalStorageUtil, memoize, MINUTE_IN_MILLIS } from '@dextools/utils';
import { ChainUtil } from './chain.util';
import { chainList } from '../constants/chain.constants';
import { Chain } from '../models/chain.model';
import type { ChainStats, Exchange, ExchangeApi, ExchangeStats } from '../models/exchange.model';
import {
  DEFAULT_API_EXCHANGE,
  DEFAULT_EXCHANGE,
  EXCHANGE_API_UNKNOWN,
  EXCHANGE_BACKGROUND_COLOR_UNKNOWN,
  EXCHANGE_GAS_PUMP_REGEX,
  EXCHANGE_LIQUIDSWAP,
  EXCHANGE_LOGO_REMOTE_BASE_URL,
  EXCHANGE_LOGO_URL_UNKNOWN,
  EXCHANGE_PUMP_FUN_REGEX,
  EXCHANGE_REF_FINANCE,
  EXCHANGE_STON_FI,
  EXCHANGE_SUNSWAP_REGEX,
  EXCHANGE_SUN_PUMP_REGEX,
  EXCHANGE_TEXT_COLOR_UNKNOWN,
  EXCHANGE_UNKNOWN,
} from '../models/exchange.model';
import type { ApiExchange, ChainExchangesData } from '../models/shared-api/base-shared-api.model';
import { TransactionType } from '../models/transaction.model';

const LOCALSTORAGE_EXCHANGES_CHECK_DATE_KEY = 'lastExchangesCheck';
const EXCHANGES_EXPIRATION_TIME_MILLIS = 30 * MINUTE_IN_MILLIS;

export class ExchangeUtil {
  /**
   * Internal subject to clear the cache when the exchangeList changes so that the util methods return correct results based on fresh data.
   * For example exchanges that were previously unknown
   */
  private static readonly _exchangesChanged$ = new Subject<void>();
  public static exchangesChanged$ = ExchangeUtil._exchangesChanged$.asObservable().pipe(debounceTime(500));
  private static readonly _exchangesAdded$ = new Subject<Chain>();
  public static exchangesAdded$ = ExchangeUtil._exchangesAdded$.asObservable();
  private static readonly _chainStatsUpdated$ = new Subject<Chain[]>();
  public static chainStatsUpdated$ = ExchangeUtil._chainStatsUpdated$.asObservable();
  private static _chainStats: ChainStats = {} as unknown as ChainStats;
  private static _chainExchangesList: ChainExchangesData = {};
  private static _fullExchangeList: ApiExchange[] = [];

  public static get chainExchangeList(): ChainExchangesData {
    return this._chainExchangesList;
  }

  public static set chainExchangeList(chainExchangeList: ChainExchangesData) {
    this._chainExchangesList = chainExchangeList;
    this.chainStats = ExchangeUtil._getChainStats(chainExchangeList);
    this.fullExchangeList = ExchangeUtil._getFullExchangeList(chainExchangeList);
    this._exchangesChanged$.next();
  }

  public static get fullExchangeList(): ApiExchange[] {
    return this._fullExchangeList;
  }

  public static set fullExchangeList(exchangeList: ApiExchange[]) {
    this._fullExchangeList = exchangeList;
  }

  public static set chainStats(chainStats: ChainStats) {
    ExchangeUtil._chainStats = chainStats;
    ExchangeUtil._chainStatsUpdated$.next(Object.keys(chainStats) as Chain[]);
  }

  public static get chainStats(): ChainStats {
    return ExchangeUtil._chainStats;
  }

  public static getGenericExchangeApi(exchangeApi?: ExchangeApi | null): string {
    if (exchangeApi == null) {
      // null values represent Uniswap v2
      return DEFAULT_API_EXCHANGE;
    }

    return exchangeApi;
  }

  /**
   * Get the exchange's name that corresponds to the given exchange api (slug) and chain.
   *
   * @param exchangeApi - Exchange API identifier (for legacy purposes if this is `null` then it falls back to Uniswap V2)
   * @param chain - Chain
   * @returns Exchange name or empty string if not found
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeNameFromExchangeApi(exchangeApi: ExchangeApi = DEFAULT_API_EXCHANGE, chain: Chain): string {
    return (
      (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => exchangeData.slug === exchangeApi)?.name ?? EXCHANGE_UNKNOWN
    );
  }

  /**
   * TODO NOT USED ANYMORE
   * Get the default Pair that corresponds to the given exchange and chain.
   *
   * @param exchangeName - Exchange name identifier
   * @param chain - Chain
   * @returns The exchange's default pair or empty string if not found
   */
  public static getDefaultPairByExchange(exchangeName: Exchange, chain: Chain): string {
    return (
      (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => exchangeData.name.toLowerCase() === exchangeName.toLowerCase())
        ?.pairDefault ?? ''
    );
  }

  /**
   * Get the exchange slug corresponding to a factory address.
   *
   * IMPORTANT: depending on the chain, the search could be case-sensitive or not.
   *
   * @param factory - Factory address
   * @param chain - Chain
   * @returns The exchange's slug
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeByFactory(factory: string, chain: Chain): string | undefined {
    let normalizedFactory = factory.toLowerCase();

    // chains with case-sensitive factory addresses coming in API
    if (chain === Chain.Tron) {
      normalizedFactory = factory;
    }

    return (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => exchangeData.factory === normalizedFactory)?.slug;
  }

  /**
   * Get the exchange version that corresponds to the given exchange and chain.
   *
   * @param exchangeApi - Exchange API identifier
   * @param chain - Chain
   * @returns The exchange's version or empty string if not found
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeVersion(exchangeApi: string, chain: Chain): string {
    return (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => exchangeData.slug === exchangeApi)?.version ?? '';
  }

  /**
   * Get the chain that corresponds to the given exchange API.
   *
   * @param exchangeApi - Exchange API identifier
   * @returns The corresponding chain (`ether` by default if not found)
   */
  public static getChainByExchangeApi(exchangeApi: ExchangeApi): Chain {
    return (
      chainList.find((chain) => {
        if ((ExchangeUtil.chainExchangeList[chain] ?? []).some((exchangeData) => exchangeData.slug === exchangeApi)) {
          return chain;
        }
        return false;
      }) ?? Chain.Ethereum
    );
  }

  /**
   * Get the exchange url swap.
   *
   * @param chain - Chain
   * @param exchangeName - Exchange name identifier
   * @param exchangeVersion - Exchange version
   * @param outputCurrency - Currency
   * @param type - Type transaction (BUY or SELL, SELL by default)
   * @returns Swap url of the exchange.
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  public static getExchangeSwapUrl(
    chain: Chain,
    exchangeName: Exchange,
    exchangeVersion = '',
    outputCurrency: string | null = null,
    type: TransactionType | null = TransactionType.SELL,
  ): string {
    // for those exchanges with multiple versions we do not have the 'v1' explicitly set on the ExchangeApi name
    const searchedExchangeVersion = exchangeVersion === 'v1' ? '' : exchangeVersion.toLowerCase();
    const exchangeSwapUrl =
      (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => {
        return (
          exchangeData.name.toLowerCase() === exchangeName.toLowerCase() &&
          (!exchangeData.version || (exchangeData.version && exchangeData.version === searchedExchangeVersion))
        );
      })?.urlSwap ?? '#';

    if (outputCurrency == null || exchangeSwapUrl === '#') {
      return exchangeSwapUrl;
    }

    let hasUrlParams: boolean;

    try {
      hasUrlParams = new URL(exchangeSwapUrl).search.length > 0;
    } catch {
      console.warn(`Could not parse swap url for ${exchangeName}. Please verify url: ${exchangeSwapUrl}`);
      hasUrlParams = false;
    }

    const paramsSeparator = hasUrlParams ? '&' : '?';

    // URL for Gas Pump is always the same regardless of the transaction type
    if (EXCHANGE_GAS_PUMP_REGEX.test(exchangeName) && chain === Chain.Ton) {
      return `${exchangeSwapUrl}${outputCurrency}`;
    }

    // URL for SunSwap v2 and Sun Pump is always the same regardless of the transaction type
    if ((EXCHANGE_SUNSWAP_REGEX.test(exchangeName) || EXCHANGE_SUN_PUMP_REGEX.test(exchangeName)) && chain === Chain.Tron) {
      return `${exchangeSwapUrl}${outputCurrency}`;
    }

    if (EXCHANGE_PUMP_FUN_REGEX.test(exchangeName) && chain === Chain.Solana) {
      return `${exchangeSwapUrl}${outputCurrency}`;
    }

    if (type === TransactionType.BUY) {
      if (exchangeName.toLowerCase() === EXCHANGE_LIQUIDSWAP.toLowerCase() && chain === Chain.Aptos) {
        return `${exchangeSwapUrl}${paramsSeparator}to=${outputCurrency}`;
      }
      if (exchangeName.toLowerCase() === EXCHANGE_STON_FI && chain === Chain.Ton) {
        return `${exchangeSwapUrl}${paramsSeparator}ft=${outputCurrency}`;
      }
      if (exchangeName.toLowerCase() === EXCHANGE_REF_FINANCE && chain === Chain.Near) {
        return `${exchangeSwapUrl}/#${outputCurrency}`;
      }
      return `${exchangeSwapUrl}${paramsSeparator}outputCurrency=${outputCurrency}`;
    }
    if (type === TransactionType.SELL) {
      if (exchangeName.toLowerCase() === EXCHANGE_LIQUIDSWAP.toLowerCase() && chain === Chain.Aptos) {
        return `${exchangeSwapUrl}${paramsSeparator}from=${outputCurrency}`;
      }
      if (exchangeName.toLowerCase() === EXCHANGE_STON_FI && chain === Chain.Ton) {
        return `${exchangeSwapUrl}${paramsSeparator}tt=${outputCurrency}`;
      }
      if (exchangeName.toLowerCase() === EXCHANGE_REF_FINANCE && chain === Chain.Near) {
        return `${exchangeSwapUrl}/#${outputCurrency}`;
      }
      return `${exchangeSwapUrl}${paramsSeparator}inputCurrency=${outputCurrency}`;
    }

    return exchangeSwapUrl;
  }

  /**
   * Get the exchange's info URL.
   *
   * @param exchangeApi - Exchange API identifier
   * @param chain - Chain
   * @returns Exchange's info url
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeInfoUrl(exchangeApi: ExchangeApi, chain: Chain): string {
    return (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => exchangeData.slug === exchangeApi)?.urlInfo ?? '#';
  }

  /**
   * Get default Exchange of Chain.
   *
   * @param chain - Chain identifier
   * @returns Exchange name (Default `uniswap` if no exchange found)
   */
  public static getDefaultExchange(chain: Chain | null): string {
    return (
      (ExchangeUtil.chainExchangeList[(chain as Chain) ?? Chain.Ethereum] ?? []).find((exchangeData) => exchangeData.isDefault)?.name ??
      DEFAULT_EXCHANGE
    );
  }

  /**
   * Get Exchange logo.
   *
   * @param exchangeApiOrName - Exchange API or exchange name
   * @param chain - Chain identifier
   * @returns The exchange's logo url or `unknown` logo if not found
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeLogoUrl(exchangeApiOrName: ExchangeApi | Exchange, chain: Chain): string {
    const foundExchangeData = (ExchangeUtil.chainExchangeList[chain] ?? []).find((exchangeData) => {
      if (exchangeData.name.toLowerCase() === exchangeApiOrName.toLowerCase() || exchangeData.slug === exchangeApiOrName) {
        return exchangeData;
      }
      return false;
    });

    return foundExchangeData ? `${EXCHANGE_LOGO_REMOTE_BASE_URL}${foundExchangeData.logo}` : `${EXCHANGE_LOGO_URL_UNKNOWN}`;
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static valueIsExchangeApi(value: unknown, chain: Chain): value is ExchangeApi {
    return (ExchangeUtil.chainExchangeList[chain] ?? []).some((exchangeData) => exchangeData.slug === value);
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static valueIsExchange(value: unknown, chain: Chain): value is Exchange {
    return (ExchangeUtil.chainExchangeList[chain] ?? []).some((exchangeData) => exchangeData.name === value);
  }

  public static getExchangeNameWithoutVersion(exchangeName: string) {
    return exchangeName.replace(/\s(.*)(v\d+)$/, '');
  }

  /**
   * Return the generic name of the exchange and its version in case the exchange has multiple versions available.
   * Returns `null` if the pair is actually from an exchange with one single version only.
   *
   * @param exchangeApi - Exchange to be checked
   * @param chain - Chain
   *
   * @returns The generic name of the exchange and its version or `null`.
   */
  public static isExchangeSpecificVersion(exchangeApi: string, chain: Chain | null = Chain.Ethereum): string | null {
    return (
      (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find(
        (exchangeData) => exchangeData.slug === exchangeApi && !!exchangeData.version,
      )?.version ?? null
    );
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeBackgroundColor(exchangeApiOrName: string, chain: Chain | null = Chain.Ethereum): string {
    return (
      (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find(
        (exchangeData) => exchangeData.name.toLowerCase() === exchangeApiOrName.toLowerCase() || exchangeData.slug === exchangeApiOrName,
      )?.backgroundColor ?? EXCHANGE_BACKGROUND_COLOR_UNKNOWN
    );
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeTextColor(exchangeApiOrName: string, chain: Chain | null = Chain.Ethereum): string {
    return (
      (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find(
        (exchangeData) => exchangeData.name.toLowerCase() === exchangeApiOrName.toLowerCase() || exchangeData.slug === exchangeApiOrName,
      )?.textColor ?? EXCHANGE_TEXT_COLOR_UNKNOWN
    );
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeApiFromExchange(exchangeName: string, chain: Chain | null = Chain.Ethereum): string {
    return (
      (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find(
        (exchangeData) => exchangeName.toLowerCase() === exchangeData.name.toLowerCase(),
      )?.slug ?? EXCHANGE_API_UNKNOWN
    );
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static hasExchangePoolRatios(exchangeApi: string, chain: Chain | null = Chain.Ethereum): boolean {
    return (
      (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find((exchangeData) => exchangeApi === exchangeData.slug)?.hasPoolRatios ??
      false
    );
  }

  public static getExchangeLimitBotSupported(exchangeName: string, chain: Chain | null = Chain.Ethereum): boolean {
    return (
      (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find(
        (exchangeData) => exchangeData.name.toLowerCase() === exchangeName.toLowerCase(),
      )?.limitBotSupported ?? false
    );
  }

  // TODO NOT USED ANYMORE
  public static isExchangeInChain(exchangeApi: string, chain: Chain | null = Chain.Ethereum): boolean {
    return (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).some((exchangeData) => exchangeData.slug === exchangeApi);
  }

  @memoize({
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangesFromChain(chain: Chain | null = Chain.Ethereum) {
    return ExchangeUtil.chainExchangeList[chain ?? Chain.Ethereum]?.filter((exchange) => exchange.name !== EXCHANGE_UNKNOWN) ?? [];
  }

  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getLogoStyle(exchangeApiOrName: string, chain: Chain | null = Chain.Ethereum): string {
    const exchangeName = exchangeApiOrName.toLowerCase();
    const logoStyles = (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find(
      (exchangeData) => exchangeData.slug === exchangeApiOrName || exchangeData.name.toLowerCase() === exchangeName,
    )?.logoStyles;
    return logoStyles ? logoStyles.replace(/[{}]/g, '') : '';
  }

  public static get isExchangeListExpired(): boolean {
    const lastCheckExchanges = LocalStorageUtil.getDate(LOCALSTORAGE_EXCHANGES_CHECK_DATE_KEY);

    if (lastCheckExchanges) {
      return Date.now() - EXCHANGES_EXPIRATION_TIME_MILLIS > lastCheckExchanges.getTime();
    }
    return true;
  }

  public static updateExchangesCheckDate() {
    LocalStorageUtil.setDate(LOCALSTORAGE_EXCHANGES_CHECK_DATE_KEY);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private static _getChainStats(chainExchanges: ChainExchangesData): ChainStats {
    const tempChainStats: ChainStats = {} as unknown as ChainStats;

    for (const chainData of ChainUtil.allChains) {
      const chain = chainData.chain;

      for (const exchange of chainExchanges[chain] ?? []) {
        if (!tempChainStats[chain]) {
          tempChainStats[chain] = {
            exchanges: [],
          };
        }
        const exchangeName = exchange.version ? ExchangeUtil.getExchangeNameWithoutVersion(exchange.name) : exchange.name;
        const exchangeItem: ExchangeStats = {
          name: exchangeName,
          logo: `${EXCHANGE_LOGO_REMOTE_BASE_URL}${exchange.logo}`,
          exchange: exchange.slug,
        };

        if (tempChainStats[chain].exchanges.length === 0 || (!exchange.version && tempChainStats[chain].exchanges.length > 0)) {
          tempChainStats[chain].exchanges.push(exchangeItem);
        } else {
          const exchangeIndex = tempChainStats[chain].exchanges.findIndex(
            (item) => item.name?.toLowerCase() === exchangeName.toLowerCase(),
          );

          if (exchangeIndex === -1) {
            tempChainStats[chain].exchanges.push(exchangeItem);
          } else {
            /** For the pancake and uniswap versions, the one with isDefault active is taken as the main one. So that it is not random */
            if (exchange.isDefault) {
              tempChainStats[chain].exchanges[exchangeIndex].exchange = exchange.slug;
            }
          }
        }
      }
    }

    return tempChainStats;
  }

  private static _getFullExchangeList(chainExchanges: ChainExchangesData) {
    let fullChainExchangeList: ApiExchange[] = [];

    for (const chain of Object.keys(chainExchanges)) {
      fullChainExchangeList = [...fullChainExchangeList, ...(chainExchanges[chain as Chain] ?? [])];
    }

    return fullChainExchangeList;
  }

  /**
   * Checks if a hexadecimal character string is valid, containing 42 hexadecimal characters,
   * with an optional format of "-number-number" and option "u" at the end of the string.
   *
   * @param address - The string to check.
   *
   * @returns `true` in case the string is valid, `false` otherwise.
   */
  public static isCurveAddress(address: string): boolean {
    const regex = /^(0x)[\dA-Fa-f]{40}(?:-\d{1,2}){2}u?$/;
    return regex.test(address);
  }

  public static isBalancerAddress(address: string): boolean {
    const regex = /^(?:0x[\dA-Fa-f]{40}-){2}0x[\dA-Fa-f]{40}$/;
    return regex.test(address);
  }

  /**
   * Get exchangeAlike.
   *
   * @param exchangeApi - Exchange slug API
   * @param chain - Chain identifier
   * @returns The alike exchange or null if not exist
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getAlikeExchange(exchangeApi: string, chain: Chain): string | null {
    return (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find((exchangeData) => exchangeApi === exchangeData.slug)?.alike ?? null;
  }

  /**
   * Return all properties of the exchange.
   *
   * @param exchangeSlug - Slug (from API) of the exchange to be checked
   * @param chain - Chain
   *
   * @returns All info about the requested exchange.
   */
  @memoize({
    resolver: (...args: unknown[]) => `${args[0]}(${args[1]})`, // the key cache consists of the combination of the 2 params
    clearSignal: ExchangeUtil.exchangesChanged$,
  })
  public static getExchangeBySlug(exchangeSlug: string, chain: Chain): ApiExchange | undefined {
    return (ExchangeUtil.chainExchangeList[chain as Chain] ?? []).find((exchangeData) => exchangeData.slug === exchangeSlug);
  }
}
