import type { ExchangeApi } from './exchange.model';
import type {
  AllTokenInfo,
  AllTokenLinks,
  ApiCategory,
  IntervalVariationData,
  Locks,
  LocksV2,
  Story,
} from './shared-api/base-shared-api.model';

export const TOKEN_CUSTOM_LOGO_REMOTE_BASE_URL = 'https://www.dextools.io/resources/tokens/logos/';

/**
 * Pair Types examples:
 * STANDARD: dext-/weth
 * STABLE_BASED: dext-/usdt
 * STABLE_NATIVE: usdt-/weth
 * TOKEN: dext/gq
 */
export enum PairType {
  STANDARD = 'standard-pair',
  STABLE_BASED = 'stable-based-pair',
  STABLE_NATIVE = 'stable-native-pair',
  TOKEN = 'token-token-pair',
}

export interface TokenPoolInfo {
  vsSymbol: string;
  value: string;
  tokenSymbol: string;
  marketCap?: string;
  totalMarketCap?: string;
  created?: string;
  totalSupply: number;
  maxSupply?: string;
  pooled?: string;
  circulatingSupply?: number;
  fee: number; // univ3
  amp?: number; // Kyber only
  ratio?: string; // Kyber only
  liquidity?: number;
  vol24h?: number;
  volatilityIndex?: number;
  bondingCurveProgress?: number;
}

export interface TokenSocials {
  twitter?: string;
  website?: string;
  telegram?: string;
  reddit?: string;
  facebook?: string;
  tiktok?: string;
  medium?: string;
  instagram?: string;
  discord?: string;
  youtube?: string;
  slack?: string;
  linkedin?: string;
  github?: string;
  bitbucket?: string;
  extraInfo?: string;
  email?: string;
  nftCollection?: string;
}

export interface TokenDataInfo extends TokenSocials {
  cmc?: string;
  coingecko?: string;
  ventures?: boolean;
}

export interface TokenData {
  exchange?: ExchangeApi;
  whitelisted?: boolean;
  id?: string;
  id_vs?: string;
  logo?: string;
  audio?: string;
  banner?: string;
  symbol?: string;
  symbol_vs?: string;
  decimals: number;
  holders: number;
  txCount: number;
  tokenInfo?: TokenDataInfo;
  name?: string;
  pooledToken: number;
  pooledETH: number;
  liquidity: number | undefined;
  liquidityPoolToken: {
    total?: number;
    burned?: number;
  };
  priceUSD: number;
  priceETH: number;
  created: number; // in milliseconds
  locks?: Locks[] | LocksV2[];
  description?: string;
  isVentures?: boolean;
  isStableNativePair: boolean;
  price?: number;
  price5m?: IntervalVariationData;
  price1h?: IntervalVariationData;
  price6h?: IntervalVariationData;
  price24h?: IntervalVariationData;
  price7d?: IntervalVariationData;
  pool?: {
    name: string;
    symbol: string;
  };
  poolPrice?: number;
  pairType: PairType;
  isFromTokenCreator: boolean;
  stories: Story[];
  categories: ApiCategory[];
}

export interface TokenInfo extends AllTokenLinks {
  email: AllTokenInfo['email'];
  nftCollection: AllTokenInfo['nftCollection'];
  extraInfo: AllTokenInfo['extraInfo'];
}

export interface TokenId {
  chain: string;
  token: string;
}

export interface ApiTokenWithInfo {
  id: TokenId;
  logo: string;
  links?: TokenSocials;
}

export interface HoldersOutput {
  holders: number;
  transactions: number;
}
