import { Chain } from '../models/chain.model';
import { Chains } from './chain.constants';

type ChainIds = Partial<Record<Chain, string>>;
type ChainIdsNumber = Partial<Record<Chain, number>>;

export const UNICRYPT = 'unicrypt';
export const UNICRYPT_V3 = 'unicrypt_v3';
export const TEAM_FINANCE = 'teamfinance';
export const TEAM_FINANCE_OLD = 'team-finance';
export const FAIR_TOKEN_PROJECT = 'fair-token-project';
export const DX_SALE = 'dxsale';
export const PINKSALE = 'pinksale';
export const MUDRA = 'mudra';
export const FLOKI = 'floki';
export const GEMPAD = 'gempad';
export const SOLTOOLS = 'soltools';
export const REF_FINANCE = 'ref-finance';

export type LockProvider =
  | typeof UNICRYPT
  | typeof PINKSALE
  | typeof DX_SALE
  | typeof FAIR_TOKEN_PROJECT
  | typeof MUDRA
  | typeof FLOKI
  | typeof TEAM_FINANCE
  | typeof GEMPAD
  | typeof SOLTOOLS
  | typeof TEAM_FINANCE_OLD
  | typeof UNICRYPT_V3
  | typeof REF_FINANCE;

/**
 * Chains supported by DxSale and the nomenclature on CHAINNAME dx.app
 * https://dx.app/?chain=CHAINNAME
 */
export const chainDxSale: Partial<ChainIds> = {
  [Chain.Ethereum]: Chains.Ethereum.id.toString(),
  [Chain.Cronos]: Chains.Cronos.id.toString(),
  [Chain.Binance]: Chains.Binance.id.toString(),
  [Chain.Oktc]: Chains.Oktc.id.toString(),
  [Chain.Gnosis]: Chains.Gnosis.id.toString(),
  [Chain.Shibarium]: Chains.Shibarium.id.toString(),
  [Chain.Polygon]: Chains.Polygon.id.toString(),
  [Chain.Fantom]: Chains.Fantom.id.toString(),
  [Chain.Kucoin]: Chains.Kucoin.id.toString(),
  [Chain.Zksync]: Chains.Zksync.id.toString(),
  [Chain.Pulse]: Chains.Pulse.id.toString(),
  [Chain.CoreDao]: Chains.CoreDao.id.toString(),
  [Chain.Moonriver]: Chains.Moonriver.id.toString(),
  [Chain.Doge]: Chains.Doge.id.toString(),
  [Chain.Milkomeda]: Chains.Milkomeda.id.toString(),
  [Chain.Alvey]: Chains.Alvey.id.toString(),
  [Chain.Base]: Chains.Base.id.toString(),
  [Chain.Smartbch]: Chains.Smartbch.id.toString(),
  [Chain.Bitgert]: Chains.Bitgert.id.toString(),
  [Chain.Arbitrum]: Chains.Arbitrum.id.toString(),
  [Chain.Celo]: Chains.Celo.id.toString(),
  [Chain.Avalanche]: Chains.Avalanche.id.toString(),
  [Chain.Harmony]: Chains.Harmony.id.toString(),
  [Chain.Blast]: Chains.Blast.id.toString(),
};

/**
 * Chains supported by PinkSale and the nomenclature on CHAINNAME https://www.pinksale.finance/
 * https://www.pinksale.finance/pinklock/<tokenOrPairContract>/chain=CHAINNAME
 */
export const chainPinkSale: Partial<ChainIds> = {
  [Chain.Ethereum]: 'ethereum',
  [Chain.Binance]: 'bsc',
  [Chain.Polygon]: 'polygon',
  [Chain.Avalanche]: 'avalanche',
  [Chain.Fantom]: 'fantom',
  [Chain.Doge]: 'dogechain',
  [Chain.Cronos]: 'cronos',
  [Chain.Arbitrum]: 'arbitrum',
  [Chain.Alvey]: 'alvey',
  [Chain.Pulse]: 'pulsechain',
  [Chain.Base]: 'base',
  [Chain.Solana]: 'solana',
};

export const chainSoltools: Partial<ChainIds> = {
  [Chain.Solana]: Chains.Solana.chain,
};

export const chainFlokiLocks: Partial<ChainIds> = {
  [Chain.Ethereum]: Chains.Ethereum.id.toString(),
  [Chain.Optimism]: Chains.Optimism.id.toString(),
  [Chain.Cronos]: Chains.Cronos.id.toString(),
  [Chain.Binance]: Chains.Binance.id.toString(),
  [Chain.Oktc]: Chains.Oktc.id.toString(),
  [Chain.Polygon]: Chains.Polygon.id.toString(),
  [Chain.Fantom]: Chains.Fantom.id.toString(),
  [Chain.Kucoin]: Chains.Kucoin.id.toString(),
  [Chain.Doge]: Chains.Doge.id.toString(),
  [Chain.Evmos]: Chains.Evmos.id.toString(),
  [Chain.Arbitrum]: Chains.Arbitrum.id.toString(),
  [Chain.Avalanche]: Chains.Avalanche.id.toString(),
  [Chain.Base]: Chains.Base.id.toString(),
};

export const chainUnicrypt: Partial<ChainIds> = {
  [Chain.Ethereum]: 'ETH',
  [Chain.EthereumGoerli]: 'Goerli',
  [Chain.Binance]: 'BNB',
  [Chain.Polygon]: 'Polygon',
  [Chain.Arbitrum]: 'Arbitrum',
  [Chain.Avalanche]: 'Avalanche',
  [Chain.Base]: 'Base',
};

export const chainTeamFinance: Partial<ChainIds> = {
  [Chain.Ethereum]: Chains.Ethereum.hexId,
  [Chain.Binance]: Chains.Binance.hexId,
  [Chain.Cronos]: Chains.Cronos.hexId,
  [Chain.Etc]: Chains.Etc.hexId,
  [Chain.Velas]: Chains.Velas.hexId,
  [Chain.Heco]: Chains.Heco.hexId,
  [Chain.Polygon]: Chains.Polygon.hexId,
  [Chain.Fantom]: Chains.Fantom.hexId,
  [Chain.Kava]: Chains.Kava.hexId,
  [Chain.Avalanche]: Chains.Avalanche.hexId,
  [Chain.Base]: Chains.Base.hexId,
  [Chain.Blast]: Chains.Blast.hexId,
};

export const chainGempad: Partial<ChainIdsNumber> = {
  [Chain.Ethereum]: Chains.Ethereum.id,
  [Chain.Binance]: Chains.Binance.id,
  [Chain.Cronos]: Chains.Cronos.id,
  [Chain.Telos]: Chains.Telos.id,
  [Chain.Meter]: Chains.Meter.id,
  [Chain.Polygon]: Chains.Polygon.id,
  [Chain.Fantom]: Chains.Fantom.id,
  [Chain.Doge]: Chains.Doge.id,
  [Chain.Alvey]: Chains.Alvey.id,
  [Chain.Muu]: Chains.Muu.id,
  [Chain.Bitgert]: Chains.Bitgert.id,
  [Chain.Arbitrum]: Chains.Arbitrum.id,
  [Chain.Shibarium]: Chains.Shibarium.id,
  [Chain.Base]: Chains.Base.id,
  [Chain.Solana]: Chains.Solana.id,
};

export const chainRefFinance: Partial<ChainIdsNumber> = {
  [Chain.Near]: Chains.Near.id,
};
