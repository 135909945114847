import {
  chainDxSale,
  chainFlokiLocks,
  chainGempad,
  chainPinkSale,
  chainRefFinance,
  chainSoltools,
  chainTeamFinance,
  chainUnicrypt,
  DX_SALE,
  FAIR_TOKEN_PROJECT,
  FLOKI,
  GEMPAD,
  MUDRA,
  PINKSALE,
  REF_FINANCE,
  SOLTOOLS,
  TEAM_FINANCE,
  TEAM_FINANCE_OLD,
  UNICRYPT,
} from '../constants/lock.constants';
import type { LockProvider } from '../constants/lock.constants';
import { Chain } from '../models/chain.model';
import type { Locks, LocksV2, LocksV2Data } from '../models/shared-api/base-shared-api.model';
import type { TokenLockData } from '../models/locks.model';

export const LOCKS_PROVIDER_LOGO_BASE_URL = 'assets/img/lockers/';

export const UNICRYPT_GENERIC_URL = 'https://beta.uncx.network/lockers/explore/pools';

export class LocksUtil {
  public static getLocksProviderUrl(lockOrProvider: Locks | LockProvider, data: TokenLockData, isToken = false) {
    const providerId =
      (lockOrProvider as Locks).providerId == null ? (lockOrProvider as LockProvider) : (lockOrProvider as Locks).providerId;

    switch (providerId) {
      case TEAM_FINANCE_OLD:
      case TEAM_FINANCE: {
        return data.id
          ? `https://team.finance/view-coin/${data.id}?name=${data.name}&symbol=${data.symbol}&chainid=${chainTeamFinance[data.chain]}`
          : 'https://team.finance';
      }
      case FAIR_TOKEN_PROJECT: {
        return 'https://fairtokenproject.com/#locks';
      }
      case DX_SALE: {
        return LocksUtil._getDxSaleURL(data?.lockerContract, data.chain, isToken);
      }
      // TODO LEGACY
      case MUDRA: {
        return `https://mudra.website/?certificate=yes&type=0&lp=${data.pair}`;
      }
      case FLOKI: {
        return (lockOrProvider as Locks)?.type === 'pair' || !isToken
          ? `https://locker.flokifi.com/view/1/${data.pair}/${chainFlokiLocks[data.chain]}`
          : `https://locker.flokifi.com/view/0/${data.id}/${chainFlokiLocks[data.chain]}`;
      }
      case GEMPAD: {
        return `https://gempad.app/locks?tab=${data.tabUrlParam ?? 0}&q=${data.id}`;
      }
      case SOLTOOLS: {
        if (!data.urlParam) {
          return 'https://app.apexpad.finance/sol/locker';
        }
        return `https://app.apexpad.finance/sol/locker/locker-details/${data.urlParam}?cluster=mainnet-beta`;
      }
      case PINKSALE: {
        if (!data.urlParam) {
          return 'https://www.pinksale.finance/pinklock/liquidity?chainName=';
        }
        return data.chain === Chain.Solana
          ? `https://www.pinksale.finance/solana/pinklock/record/${data.urlParam}`
          : `https://www.pinksale.finance/pinklock/${chainPinkSale[data.chain]}/${data.urlParam}`;
      }
      case REF_FINANCE: {
        return `https://app.ref.finance/pool/${data.pair}`;
      }
      default: {
        if ((lockOrProvider as Locks)?.lockUrl) {
          return (lockOrProvider as Locks)?.lockUrl ?? null;
        }

        return UNICRYPT_GENERIC_URL;
      }
    }
  }

  public static getLocksProviderLogo(provider: LockProvider) {
    switch (provider) {
      case TEAM_FINANCE_OLD:
      case TEAM_FINANCE: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}team.png`;
      }
      case FAIR_TOKEN_PROJECT: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}fair-token-project.png`;
      }
      case DX_SALE: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}dx.png`;
      }
      case PINKSALE: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}pinksale.png`;
      }
      case SOLTOOLS: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}soltools.webp`;
      }
      // TODO LEGACY
      case MUDRA: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}mudra.png`;
      }
      case FLOKI: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}floki.png`;
      }
      case GEMPAD: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}gem-pad.png`;
      }
      case REF_FINANCE: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}ref-finance.png`;
      }
      default: {
        return `${LOCKS_PROVIDER_LOGO_BASE_URL}uncx.svg`;
      }
    }
  }

  public static isLocksProviderSupportedInChain(provider: LockProvider, chain: Chain): boolean {
    switch (provider) {
      case UNICRYPT: {
        return !!chainUnicrypt[chain];
      }
      case PINKSALE: {
        return !!chainPinkSale[chain];
      }
      case SOLTOOLS: {
        return !!chainSoltools[chain];
      }
      case DX_SALE: {
        return !!chainDxSale[chain];
      }
      case TEAM_FINANCE_OLD:
      case TEAM_FINANCE: {
        return !!chainTeamFinance[chain];
      }
      // TODO LEGACY
      case MUDRA: {
        return chain === Chain.Binance;
      }
      case FLOKI: {
        return !!chainFlokiLocks[chain];
      }
      case GEMPAD: {
        return !!chainGempad[chain];
      }
      case REF_FINANCE: {
        return !!chainRefFinance[chain];
      }
      default: {
        return false;
      }
    }
  }

  private static _getDxSaleURL(lockerContract: string | undefined, chain: Chain, isToken: boolean) {
    if (!lockerContract) {
      return 'https://dx.app/';
    }
    const typeDxSale = isToken ? 'token-locker' : 'liquidity-locker';

    return `https://dx.app/dxlock/view/${typeDxSale}?address=${lockerContract}&chain=${chainDxSale[chain]}`;
  }

  public static processesLocks(liquidityLock: Locks[] | LocksV2[]): Locks[] | LocksV2[] {
    if (liquidityLock.length === 0) {
      return [];
    }
    // Process and remove duplicate and invalid items
    return this._removeDuplicateLocks(liquidityLock).filter((lock) => this._checkValidLock(lock));
  }

  public static formatLocks(locks: LocksV2Data | readonly Locks[]): Locks[] | LocksV2[] {
    return this._isLocksV2(locks) ? this._convertToLocksArray(locks) : [...locks];
  }

  private static _isLocksV2(locks: LocksV2Data | readonly Locks[]): locks is LocksV2Data {
    return !Array.isArray(locks);
  }

  private static _convertToLocksArray(locksData: LocksV2Data): LocksV2[] {
    return Object.values(locksData).flatMap((providerLock) => Object.values(providerLock.locks ?? {}));
  }

  private static _removeDuplicateLocks(liquidityLock: Locks[] | LocksV2[]): Locks[] | LocksV2[] {
    const uniqueLocks: Locks[] = [];
    liquidityLock.map((lock) =>
      uniqueLocks.some((locks) => locks.lockId === lock.lockId || (locks.unlockDate === lock.unlockDate && locks.amount === lock.amount))
        ? null
        : uniqueLocks.push(lock),
    );
    return uniqueLocks;
  }

  private static _checkValidLock(lock: Locks): boolean {
    return new Date(lock.unlockDate).getTime() - Date.now() > 0 && !Number.isNaN(lock.amount);
  }
}
