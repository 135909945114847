import type { Chain } from './chain.model';
import type { SwapTx, AddressTx, TransferTx } from './shared-api/base-shared-api.model';
import type { Swap, SwapSize } from './swap.model';
import type { MakerBalanceSummary } from './maker.model';

export interface TxData {
  status: boolean;
  date: number;
  from: AddressTx;
  to: AddressTx;
  chain: Chain;
  gasPrice: number;
  gasLimit: number;
  gasPriceInNative: number;
  gasPriceInUsd: number;
  gasPriceInUsdCurrent: number;
  swaps: SwapTx[];
  transfers: TransferTx[];
  hash: string;
  block: number;
  value: number;
  valueInUsd: number;
  valueInUsdCurrent: number;
  valueInNative: number;
  txFee: number;
  txFeeInNative: number;
  txFeeInUsd: number;
  txFeeInUsdCurrent: number;
}

export enum TransactionType {
  SELL = 'sell',
  BUY = 'buy',
  ADD = 'add', // liquidity
  REMOVE = 'remove', // liquidity
}

export interface TransactionBlock {
  blockNumber: number;
  logIndex: number;
}

// TODO. Maintain this interface and see if we can replace Swap (swap.model.ts) with this one in the future.
export interface Transaction extends TransactionBlock {
  amountETH: number;
  amountRef: number;
  amountToken: number;
  amountUSD: number;
  blockHash: string;
  frontRunning?: number;
  eventLiquidity: number;
  id: string;
  maker: string;
  others: { bot: boolean; botAddress: string };
  pool: { amountToken: number; amountTokenRef: number; liquidity: number };
  poolLiquidityChange?: { liquidity: number; totalUSD: number };
  price: number;
  priceETH: number;
  size?: SwapSize;
  timestamp: number;
  type: TransactionType;
}

export interface ApiTransactions {
  count: number;
  events: Swap[];
  next?: TransactionBlock;
  makers?: MakerBalanceSummary[];
}

export interface TransactionsResult {
  percentSuccess: number;
  analysisResultPercent: number;
  isOnlyBuySwaps: boolean;
}

export interface TransactionFilters {
  minPrice?: string;
  maxPrice?: string;
  minPriceETH?: string;
  maxPriceETH?: string;
  minAmount?: string;
  maxAmount?: string;
  minAmountRef?: string;
  maxAmountRef?: string;
  minTotal?: string;
  maxTotal?: string;
  minTotalEth?: string;
  maxTotalEth?: string;
  type?: string;
  //This property is always sent in plural to the endpoint, even if the filter is for just one maker.
  // If there are more than one, they are separated by commas.
  makers?: string;
}

// TODO. Fill with rest of filters
export interface Filters {
  maker: string | null;
}
