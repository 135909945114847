import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: 'app-verified-logo',
  templateUrl: './verified-logo.component.html',
  styleUrl: './verified-logo.component.scss',
  imports: [TranslateModule],
})
export class VerifiedLogoComponent {}
