import { TextUtils } from '@dextools/utils';
import { Chain } from '../models/chain.model';
import type { ApiLiquidityResponse, LiquidityData } from '../models/liquidity.model';
import type { PairDataModel } from '../models/pair-data/pair-data.model';
import type { PriceDataModel, PriceVariationDataModel } from '../models/pair-data/price-data.model';
import type { VotesDataModel } from '../models/pair-data/votes-data.model';
import type { AllPairDataMapped, ApiVotes, Locks, LocksV2 } from '../models/shared-api/base-shared-api.model';
import { PairType, TOKEN_CUSTOM_LOGO_REMOTE_BASE_URL } from '../models/token.model';
import { BALANCER_ADDRESS_LENGTH, ChainUtil, CURVE_ADDRESS_LENGTH, ETHEREUM_ADDRESS_LENGTH } from './chain.util';
import { ExchangeUtil } from './exchange.util';
import { LocksUtil } from './locks.util';
import type { PairURL } from '../models/pair-url.model';
import type { ApiPeriodStats, ApiPeriodStatsModel, PeriodStats, PeriodStatsMapped } from '../models/pair-data/period-stats.model';
import { EPeriodStats } from '../models/pair-data/period-stats.model';

export const PAIR_EXPLORER_URL_REGEX = /^[\s\w#%./:<>?-]*$/;

export class PairsUtil {
  public static filterPairsWithValidSymbol(chain: Chain, pairList: AllPairDataMapped[]): AllPairDataMapped[] {
    return pairList.filter((pair) => {
      if (
        ChainUtil.isChainSymbol(chain, pair.symbol) ||
        ChainUtil.isStableSymbol(chain, pair.symbol) ||
        ChainUtil.isChainSymbol(chain, pair.symbolRef) ||
        ChainUtil.isStableSymbol(chain, pair.symbolRef)
      ) {
        return pair;
      }

      return false;
    });
  }

  public static isStableNativePair(pairType?: string) {
    return pairType === PairType.STABLE_NATIVE;
  }

  public static normalizeLogoUrl(logoURL: string) {
    if (!logoURL) {
      return '';
    }
    if (logoURL.includes('https://') || logoURL.includes('http://')) {
      return logoURL;
    }
    return TOKEN_CUSTOM_LOGO_REMOTE_BASE_URL + logoURL;
  }

  public static normalizeAptosTokenId(id: string): string {
    return id.split(':', 1).shift() ?? '';
  }

  public static normalizeAptosPairAddress(address: string): string {
    return address.split('<', 1).shift() ?? '';
  }

  public static getPriceVariation(price: number, lastPrice: number) {
    if (!!price && !!lastPrice) {
      return (price / lastPrice - 1) * 100;
    }
    return 0;
  }

  public static getPublicAddress(address: string, chain: Chain) {
    if (ChainUtil.isEVMChain(chain)) {
      return address.slice(0, ETHEREUM_ADDRESS_LENGTH);
    }
    return address;
  }

  public static checkIsCurveAddress(address: string, chain: Chain) {
    return ChainUtil.isEVMChain(chain) && ExchangeUtil.isCurveAddress(address) && !TextUtils.REGEX_SAFE_STRING.test(address);
  }

  public static checkIsBalancerAddress(address: string, chain: Chain) {
    return ChainUtil.isEVMChain(chain) && ExchangeUtil.isBalancerAddress(address) && !TextUtils.REGEX_SAFE_STRING.test(address);
  }

  public static checkIsEVMAddress(address: string, chain: Chain) {
    return ChainUtil.isEVMChain(chain) && !this.checkIsCurveAddress(address, chain) && !this.checkIsBalancerAddress(address, chain);
  }

  public static getEVMPairAddressAndFragmentByURL(urlPair: string, chain: Chain): PairURL {
    let pair = urlPair.slice(0, CURVE_ADDRESS_LENGTH).toLowerCase();
    if (this.checkIsCurveAddress(pair, chain)) {
      return { pair: pair, fragment: urlPair.slice(CURVE_ADDRESS_LENGTH + 1) || null };
    }

    pair = urlPair.slice(0, BALANCER_ADDRESS_LENGTH).toLowerCase();
    if (this.checkIsBalancerAddress(pair, chain)) {
      return { pair: pair, fragment: urlPair.slice(BALANCER_ADDRESS_LENGTH + 1) || null };
    }

    return { pair: urlPair.slice(0, ETHEREUM_ADDRESS_LENGTH), fragment: urlPair.slice(ETHEREUM_ADDRESS_LENGTH + 1) || null };
  }

  /**
   * Get pair URL from the chain scanner
   *
   * @param chain - The chain
   * @param address - The pair address
   * @returns The pair URL
   */
  public static getPairUrlFromChainScanner(chain: Chain, address: string): string {
    const chainScannerData = ChainUtil.getScannerDataByChain(chain);

    switch (chain) {
      case Chain.Solana:
      case Chain.Aptos: {
        const pairAddress = PairsUtil.normalizeAptosPairAddress(address);
        return `${chainScannerData.url}/account/${pairAddress}`;
      }
      case Chain.Heco:
      case Chain.Kujira:
      case Chain.Starknet: {
        return `${chainScannerData.url}/contract/${address}`;
      }
      case Chain.Ton: {
        return `${chainScannerData.url}/${address}`;
      }
      case Chain.Hedera: {
        return `${chainScannerData.url}/mainnet/contract/${address}`;
      }
      case Chain.Tron: {
        return `${chainScannerData.url}/#/contract/${address}`;
      }
      default: {
        return `${chainScannerData.url}/address/${address}`;
      }
    }
  }

  /**
   * Format pair data from endpoint to model needed
   *
   * @param allPairData - The raw data
   *
   * @returns Formatted pair data
   */
  public static formatPair(allPairData: AllPairDataMapped): PairDataModel {
    const exchange = ExchangeUtil.getExchangeBySlug(allPairData.id.exchange, allPairData.id.chain);
    const isStableNative = PairsUtil.isStableNativePair(allPairData.type);

    const symbol = isStableNative ? allPairData.symbolRef : allPairData.symbol;
    const symbolRef = isStableNative ? allPairData.symbol : allPairData.symbolRef;

    let locks: Locks[] | LocksV2[] = [];
    if (allPairData.locks) {
      locks = LocksUtil.formatLocks(allPairData.locks);
    }

    // Kyber specific data
    const ratio =
      allPairData.ratio0 && allPairData.ratio1 ? `${allPairData.ratio0}% ${symbol} - ${allPairData.ratio1}% ${symbolRef}` : undefined;
    const amp = allPairData.ampBps ? allPairData.ampBps / 10_000 : undefined;

    return {
      address: allPairData.id.pair,
      chain: allPairData.id.chain,
      created: new Date(allPairData.creationTime).getTime(),
      exchange: {
        slug: exchange?.slug ?? '',
        name: exchange?.name ?? '',
        version: exchange?.version ?? '',
        hasExchangePoolRatios: exchange?.hasPoolRatios ?? false,
      },
      isStableNative,
      name: isStableNative ? allPairData.nameRef : allPairData.name,
      symbol,
      symbolRef,
      pool: {
        name: isStableNative ? allPairData.nameRef : allPairData.name,
        symbol: symbol,
      },
      pairType: allPairData.type,
      locks,
      whitelisted: allPairData.votes?._warning === -1,
      fee: allPairData.fee,
      amp,
      ratio,
      team: allPairData.team,
    };
  }

  /**
   * Calculates pair prices
   *
   * @param data - All pair data
   *
   * @returns Object with all the prices
   */
  public static calculatePairPrices(data: AllPairDataMapped): PriceDataModel {
    const isStableNative = PairsUtil.isStableNativePair(data.type);

    const pooledToken = isStableNative ? data.metrics.reserveRef : data.metrics.reserve;
    const pooledETH = isStableNative ? data.metrics.reserve : data.metrics.reserveRef;
    const priceETH = pooledETH / pooledToken;

    return {
      priceUsd: data.price,
      priceETH,
      poolPriceUsd: data.poolPrice,
    };
  }

  /**
   * Gets pair price variations
   *
   * @param data - All pair data
   *
   * @returns Object with all the price variations
   */
  public static getPairPriceVariations(data: AllPairDataMapped): PriceVariationDataModel {
    return {
      price5m: data.price5m?.price,
      price1h: data.price1h?.price,
      price6h: data.price6h?.price,
      price24h: data.price24h?.price,
      price7d: data.price7d?.price,
    };
  }

  /**
   * Formats pair votes
   *
   * @param votes - Pair votes data
   *
   * @returns Object with the formatted votes
   */
  public static formatPairVotes(votes: ApiVotes): VotesDataModel {
    const totalVotes = votes.downvotes + votes.upvotes;

    return {
      downvotes: {
        total: votes.downvotes,
        percent: (votes.downvotes * 100) / totalVotes,
      },
      total: totalVotes,
      upvotes: {
        total: votes.upvotes,
        percent: (votes.upvotes * 100) / totalVotes,
      },
    };
  }

  /**
   * Gets the burned liquidity percentage.
   * The burned liquidity percentage is considered to be 0 under following conditions:
   * - either the total token liquidity or burned token liquidity is undefined/null
   * - either the total token liquidity or burned token liquidity is 0
   * - the burned token liquidity is higher than the total token liquidity
   *
   * @param burnedLiquidity - Burned liquidity
   * @param totalLiquidity - Total token liquidity
   * @returns The burned liquidity percentage
   */
  public static getBurnedLiquidityPercentage(burnedLiquidity?: number, totalLiquidity?: number): number {
    if (totalLiquidity && burnedLiquidity && totalLiquidity >= burnedLiquidity) {
      return (burnedLiquidity * 100) / totalLiquidity;
    }

    return 0;
  }

  public static proccessLiquidityData(liquidity: ApiLiquidityResponse['data']): LiquidityData[] {
    return liquidity.makers.map((maker, index) => {
      return {
        ...maker,
        id: index + 1,
        percent: liquidity.total.balanceLpToken ? (maker.balanceLpToken / liquidity.total.balanceLpToken) * 100 : 0,
        // Remove and burn are negative values, ts absolute value can be painted
        balanceLpTokenRemoved: Math.abs(maker.balanceLpTokenRemoved),
        balanceLpTokenBurned: Math.abs(maker.balanceLpTokenBurned),
      };
    });
  }

  private static _mapPeriodStats(period: Partial<ApiPeriodStats> | null): PeriodStats {
    return {
      volume: period?.volume?.total ?? 0,
      priceVariationUsd: period?.price?.usd.last ?? 0,
      minPriceUsd: period?.price?.usd.min ?? 0,
      maxPriceUsd: period?.price?.usd.max ?? 0,
      makers: period?.makers ?? 0,
      volumeSells: period?.volume?.sells ?? 0,
      volumeBuys: period?.volume?.buys ?? 0,
      totalTransactions: period?.swaps?.total ?? 0,
      volatility: period?.volatility ?? 0,
      buys: period?.swaps?.buys ?? 0,
      sells: period?.swaps?.sells ?? 0,
    };
  }

  public static formatPeriodStats(periodStats: ApiPeriodStatsModel): PeriodStatsMapped {
    return {
      period5m: this._mapPeriodStats(periodStats[EPeriodStats.period5m]),
      period1h: this._mapPeriodStats(periodStats[EPeriodStats.period1h]),
      period6h: this._mapPeriodStats(periodStats[EPeriodStats.period6h]),
      period24h: this._mapPeriodStats(periodStats[EPeriodStats.period24h]),
    };
  }
}
