// Direct API response model
export enum EPeriodStats {
  period5m = '5m',
  period1h = '1h',
  period6h = '6h',
  period24h = '24h',
}

export interface ApiPeriodStatsModel {
  [EPeriodStats.period5m]: ApiPeriodStats;
  [EPeriodStats.period1h]: ApiPeriodStats;
  [EPeriodStats.period6h]: ApiPeriodStats;
  [EPeriodStats.period24h]: ApiPeriodStats;
}

export interface ApiPeriodStats {
  volume: SwapsPeriod;
  swaps: SwapsPeriod;
  price: PricePeriod;
  liquidity: Liquidity;
  volatility: number;
  makers: number;
  updatedAt: Date | string;
}

// Transformed model for manipulation in the application
export interface PeriodStatsMapped {
  period5m: PeriodStats;
  period1h: PeriodStats;
  period6h: PeriodStats;
  period24h: PeriodStats;
}

export interface PeriodStats {
  volume: number;
  priceVariationUsd: number;
  minPriceUsd: number;
  maxPriceUsd: number;
  makers: number;
  volumeSells: number;
  volumeBuys: number;
  totalTransactions: number;
  volatility: number;
  buys: number;
  sells: number;
}

// Shared interfaces for price, liquidity and trading data
interface Liquidity {
  usd: PriceCandle;
}

interface PricePeriod {
  usd: PriceCandle;
  chain: PriceCandle;
}

interface PriceCandle {
  first: number;
  last: number;
  min: number;
  max: number;
  diff: number;
}

interface SwapsPeriod {
  total: number;
  buys: number;
  sells: number;
}
